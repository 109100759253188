import "./App.css";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Login from "./components/Login";
import { useState } from "react";
import Admin from "./components/Admin";
import MellowEnclave from "./components/Help";

function Home() {
  return (
    <div className="main-input-div">
      <input className="main-input" placeholder="Search Id" />
    </div>
  );
}
function App() {
  const [loginShow, setLoginShow] = useState(true);
  const [logText, setLogText] = useState("Log In");
  return (
    <div>
      <div>
        <header className="App-header">Mellow Enclave</header>
      </div>
      <BrowserRouter>
        <div className="App">
          {loginShow ? (
            <Link to={"/login"}>
              <div className="log">
                <button
                  onClick={() => {
                    setLoginShow(false);
                    setLogText("Loggin In");
                  }}
                >
                  Login
                </button>
              </div>
            </Link>
          ) : (
            <Link to={"/"}>
              <div className="log">
                <button
                  onClick={() => {
                    setLoginShow(true);
                    setLogText("Log In");
                  }}
                >
                  {logText}
                </button>
              </div>
            </Link>
          )}
        </div>

        <Link to={"/help"}>
          <div className="help">?</div>
        </Link>

        <Routes>
          {!loginShow ? <Route path="/login" element={<Login />} /> : <></>}
          <Route path="/admin" element={<Admin />} />
        </Routes>
        <Routes>
          <Route path="/help" element={<MellowEnclave />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
