import React from "react";
import "../css/MellowEncalve.css"; // You can create a CSS file for styling
import { useNavigate } from "react-router-dom";

function MellowEnclave() {
  const navigate = useNavigate();
  return (
    <div className="help-container">
      <div className="mellow-enclave-container">
        <button
          onClick={() => {
            navigate("/");
          }}
        >
          Back
        </button>
        <h1>Welcome to Mellow Enclave</h1>
        <p>Unlock a World of Opulence and Possibilities!</p>
        <p>
          Are you ready to elevate your lifestyle and join the ranks of the
          truly affluent? Look no further than Mellow Enclave – the epitome of
          exclusivity, wisdom, and prosperity.
        </p>
        <h2>Validation that Speaks Volumes</h2>
        <p>
          We understand that true wealth goes beyond just financial status. It's
          about the art of living, the appreciation of beauty, and the capacity
          to embrace life's pleasures. Our validation process doesn't just rely
          on numbers; it's about your ability to appreciate and create value in
          every moment.
        </p>
        <h2>Join the League of Visionaries</h2>
        <p>
          Mellow Enclave is your passport to a community of like-minded
          individuals who are setting trends, forging new paths, and shaping the
          future. Here, networking isn't just a buzzword – it's a way of life.
          Rub shoulders with pioneers, thought leaders, and trendsetters who
          share your passion for living life to the fullest.
        </p>
        <h2>Empower Your Spending Potential</h2>
        <p>
          Mellow Enclave isn't just a club; it's a catalyst for taking your
          aspirations from dreams to reality. Imagine the power of connecting
          with individuals who share your vision and can open doors you didn't
          even know existed. Your $50,000.00 investment isn't just a fee; it's a
          key to unlocking a world of opportunities, connections, and
          experiences that money alone can't buy.
        </p>
        {/* Add more sections here */}
        <h2>Elevate Your Reality Today</h2>
        <p>
          The time to act is now. Mellow Enclave is where you belong, and your
          destiny awaits. Are you ready to unlock a future that's adorned with
          the finest experiences life has to offer? Join us and let's embark on
          this remarkable journey together.
        </p>
        <p>
          Note: Mellow Enclave is committed to creating an inclusive community
          that values individuals from all walks of life. Our validation process
          is designed to ensure alignment with our values and commitment to
          excellence.
        </p>
      </div>
    </div>
  );
}

export default MellowEnclave;
